<template>
    <div class="box flex-space mb-2">
        <router-link :to="{ name: 'issues.show', params: { issue_number: issue.issue_number } }" class="flex-space">
            <div class="text-large">{{ issue.title }}</div>
        </router-link>

        <div class="chip chip-lg" :class="[issue.status ? 'chip-error' : 'text-gray']">
            <i class="fas fa-circle"></i>
            <span class="ml-2">{{ $t('issues.issue_status', issue.status) }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: { issue: Object },
}
</script>