<template>
    <div class="files-show">
        <div class="context-bar">
            <div class="context-left">
                <router-link v-if="backRoute" class="btn btn-sm s-circle" :to="{ name: backRoute }" exact>
                    <i class="fas fa-arrow-left"></i>
                </router-link>
                <h2 :title="referenceInfo" @click="$emit('editReference')" @mouseenter="fetchReferenceInfo()">
                    <span>{{ file.scan_code }}</span>
                </h2>
                <button class="close-file btn btn-sm s-circle" @click="$emit('toggleClosed')">
                    <i class="fas fa-xs fa-fw" :class="[file.closed_at ? 'fa-lock' : 'fa-lock-open']"></i>
                </button>
            </div>

            <div class="context-right">
                <slot name="actions"></slot>
            </div>
        </div>

        <div class="box page-header">
            <div style="flex: 1">
                <table class="page-header-info">
                    <tbody>
                        <tr>
                            <th>{{ $t('files.uploads') }}</th>
                            <th>{{ $t('main.created') }}</th>
                            <th class="processing-time">{{ $t('files.processing_time') }}</th>
                            <th v-if="file.locations?.length">{{ $t('main.teams') }}</th>
                            <th v-if="file.issues?.length">{{ $t('main.issues') }}</th>
                        </tr>
                        <tr>
                            <td>
                                <div class="flex-start">
                                    <h2>{{ uploadsCount }}</h2>
                                    <div v-if="uploadsWithDamageCount" class="chip chip-lg bg-error ml-2">
                                        <i class="far fa-circle-exclamation"></i>
                                        <span class="ml-2">{{ uploadsWithDamageCount }}</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <DateTime :date="file.created_at"></DateTime>
                            </td>
                            <td class="processing-time">{{ processingTime }}</td>
                            <td v-if="file.locations?.length">
                                <AvatarRow :items="file.locations">
                                    <template #avatar="{ item }">
                                        <div class="tooltip tooltip-bottom" :data-tooltip="item.location">
                                            <Avatar :initials="item.location"></Avatar>
                                        </div>
                                    </template>
                                    <template #remainingItems="{ items }">
                                        <div class="tooltip tooltip-bottom"
                                            :data-tooltip="items.map(i => i.location).join('\n')">
                                            <figure class="avatar bg-dark" :data-initial="`+${items.length}`"></figure>
                                        </div>
                                    </template>
                                </AvatarRow>
                            </td>
                            <td v-if="file.issues?.length">
                                <FileShowIssues :issues="file.issues"></FileShowIssues>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <Fields v-if="file.fields?.length" :fields="file.fields" hideSnapFields class="mt-2"></Fields>

                <blockquote v-if="file.share?.comment">
                    <div>{{ file.share.comment }}</div>
                    <span class="text-gray">{{ file.share.shared_by }}</span>
                </blockquote>
            </div>

            <QRCode :value="file.scan_code"></QRCode>
        </div>

        <div class="flex-space my-4" style="gap: 12px; flex-wrap: wrap">
            <FileWorkflowRunFilterDropdown v-model="viewRun" :workflow_runs="file.workflow_runs" class="mr-auto" />

            <div class="flex-space">
                <FileCountersDropdown v-model="filter" :file="file" @refresh="$emit('refresh')" />

                <button class="btn mx-2" @click="toggleSort()"
                    :title="[sortDesc ? $t('files.sort_old_to_new') : $t('files.sort_new_to_old')]">
                    <i class="far fa-fw" :class="[sortDesc ? 'fa-sort-numeric-down' : 'fa-sort-numeric-up']"></i>
                </button>

                <button class="btn" @click="toggleView()"
                    :title="[viewType === 'list' ? $t('files.view_list') : $t('files.view_grid')]">
                    <i class="fas fa-fw" :class="[viewType === 'list' ? 'fa-list' : 'fa-grid']"></i>
                </button>
            </div>
        </div>

        <Timeline :uploads="file.uploads" :form_submits="file.form_submits" :fields="file.fields"
            :workflow_runs="file.workflow_runs" :filter="filter" :sortDesc="sortDesc" :viewType="viewType"
            :viewRun="viewRun" :hideUploader="hideUploader" @refresh="$emit('refresh')">
        </Timeline>
    </div>
</template>

<script>
import QRCode from '~/composition/QRCode.vue'
import Avatar from '~/components/Avatar.vue'
import AvatarRow from '~/components/AvatarRow.vue'
import Fields from '~/components/files/FileFields.vue'
import Timeline from '~/components/timeline/Timeline.vue'
import FileShowIssues from '~/components/files/FileShowIssues.vue'
import FileCountersDropdown from '~/components/files/FileCountersDropdown.vue'
import FileWorkflowRunFilterDropdown from '~/components/files/FileWorkflowRunFilterDropdown.vue'

const getContainerLookups = () => import('~/assets/container_lookups.json').then(m => m.default || m)

export default {
    props: {
        file: Object,
        backRoute: String,
    },

    components: {
        Avatar,
        AvatarRow,
        QRCode,
        Fields,
        Timeline,
        FileShowIssues,
        FileCountersDropdown,
        FileWorkflowRunFilterDropdown,
    },

    data() {
        return {
            referenceInfo: null,

            filter: this.$route.query.filter || 'all',
            sortDesc: this.$store.state.timelineSortDesc,
            viewType: this.$store.state.timelineViewType,
            viewRun: parseInt(this.$route.query.run) || null,

            canClose: this.$store.getters.can('Close files'),
        }
    },

    created() {
        // Since the server does not return file->uploads->fields (anymore), 
        // We need to get them from file->fields and assign them based on the snap_id
        const snapFieldsMap = Object.groupBy(this.file.fields.filter(f => f.snap_id), field => field.snap_id)

        this.file.uploads.forEach(upload => {
            upload.fields = snapFieldsMap[upload.id]
        })
    },

    mounted() {
        document.title = `${this.$t('main.files')} | ${this.file.scan_code} | Cargosnap`

        setTimeout(() => {
            const hash = window.location.hash
            const element = document.getElementById(hash.substring(1))

            if (element) {
                element.scrollIntoView()
            } else {
                window.scrollTo(0, 0)
            }
        }, 0)
    },

    computed: {
        hideUploader() {
            return Boolean(this.file.share)
        },

        uploadsCount() {
            return this.file.uploads.length
        },

        uploadsWithDamageCount() {
            return this.file.uploads.filter(u => u.has_damage).length
        },

        processingTime() {
            return dayjs(this.file.created_at).to(this.file.updated_at, true)
        },
    },

    methods: {
        toggleSort() {
            this.sortDesc = !this.sortDesc
            this.$store.commit('UPDATE_TIMELINE_SORT_DESC', this.sortDesc)

            this.$gtag.event('file_sort_selected', { new_to_old: this.sortDesc })
        },

        toggleView() {
            this.viewType = this.viewType === 'list' ? 'grid' : 'list'
            this.$store.commit('UPDATE_TIMELINE_VIEW_TYPE', this.viewType)

            this.$gtag.event('file_view_selected', { view: this.viewType })
        },

        async fetchReferenceInfo() {
            const lookups = await getContainerLookups()
            const lookup = lookups[this.file.scan_code.substring(0, 4)]

            if (!lookup) return

            const { company, country, city } = lookup

            this.referenceInfo = `${company}\n${city}, ${country}`
        },
    },
}
</script>
