<template>
    <div>
        <div class="flex-space">
            <div class="text-large text-bold">{{ $t('issues.assignees') }}</div>
            <Dropdown class="issue-assignees-dropdown dropdown-right dropdown-big btn-action" @open="focusSearch()"
                @close="$emit('update')">
                <template #toggle>
                    <i class="fas fa-plus fa-fw"></i>
                </template>
                <div class="nav">
                    <div class="has-icon-left has-icon-right">
                        <input v-model="search" class="form-input" :placeholder="$t('main.search')"
                            @keyup.enter="addBySearch()" ref="search" />

                        <i class="form-icon far fa-magnifying-glass"></i>
                        <i class="form-icon far fa-xmark c-hand" @click="search = ''"></i>
                    </div>

                    <div v-if="modelValue.length" class="mt-2">
                        <div v-for="assignee in modelValue" :key="assignee.id" class="flex-start mt-2">
                            <Avatar :initials="assignee.assignee?.name"></Avatar>
                            <div class="ml-2 mr-auto">{{ assignee.assignee?.name }}</div>
                            <button class="btn btn-clear" @click="removeAssignee(assignee)"></button>
                        </div>

                        <div v-if="availableAssignees.length" class="divider mt-4 mb-0"></div>
                    </div>

                    <div v-if="availableAssignees.length" class="mt-4">
                        <div class="text-small text-bold">{{ $t('issues.assign_users') }}</div>
                        <div v-for="user in availableAssignees" :key="user.id" @click="addAssignee(user)"
                            class="flex-start mt-2 c-hand" :class="{ ghost: user.assigning }">
                            <Avatar :initials="user.name"></Avatar>
                            <div class="ml-2" v-html="user.search || user.name"></div>
                        </div>
                    </div>
                </div>
            </Dropdown>
        </div>

        <div v-for="assignee in modelValue" :key="assignee.id" class="flex-start mt-2">
            <Avatar :initials="assignee.assignee?.name"></Avatar>
            <div class="text-dark text-bold ml-2">{{ assignee.assignee?.name }}</div>
        </div>
    </div>
</template>

<script>
import Avatar from '~/components/Avatar.vue'

export default {
    props: {
        modelValue: {
            type: Array,
            required: true,
        },
    },

    components: { Avatar },

    data() {
        return {
            users: [],
            search: '',
            issue_number: this.$route.params.issue_number,
        }
    },

    computed: {
        availableAssignees() {
            const available = this.users.filter(this.assigneeHasBeenNotChosen)

            if (this.search) {
                const filtered = available.filter(user => user.name.toLowerCase().includes(this.search.toLowerCase()))
                return filtered.map(s => ({ ...s, search: this.searchToBold(s.name, this.search) }))
            }

            return available
        }
    },

    async mounted() {
        await this.fetchUsers()
    },

    methods: {
        focusSearch() {
            setTimeout(() => this.$refs.search?.focus())
        },

        addBySearch() {
            if (!this.search) return
            if (this.availableAssignees.length !== 1) return

            this.addAssignee(this.availableAssignees[0])
            this.search = ''
        },

        searchToBold(result, query) {
            const regExp = new RegExp(query.split('')
                .map(x => x.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'))
                .join('[-\\s.]*'), 'ig')

            return result.replace(regExp, '<b>$&</b>')
        },

        assigneeHasBeenNotChosen(user) {
            return !this.modelValue.some(({ assignee }) => assignee?.id === user.id)
        },

        async fetchUsers() {
            const { data: paginator } = await this.$axios.get('users', {
                params: { limit: 200, sort: 'name', by: 'asc' },
            })

            this.users = paginator.data
        },

        async addAssignee(user) {
            this.search = ''
            user.assigning = true

            const { data } = await this.$axios.post(`issues/${this.issue_number}/assignments`, {
                assignee_id: user.id, issue_id: this.issue_number, assignee: user
            })

            this.modelValue.push(data)
            user.assigning = false
        },

        async removeAssignee(assignee) {
            this.modelValue.splice(this.modelValue.indexOf(assignee), 1)
            await this.$axios.patch(`issues/${this.issue_number}/assignments/${assignee.id}`)
        },
    },
}
</script>