<template>
    <Modal v-if="!useReportInBrowser" :title="$t('files.create_report')" @close="back()">
        <div class="form-group">
            <Errors :errors="errors" />
        </div>

        <div class="form-group">
            <label class="form-label" for="name">{{ $t('main.name') }}</label>
            <div class="input-group">
                <input class="form-input" v-model="report.name" id="name" required />
                <select class="form-select text-uppercase" v-model="report.format" style="flex:0" required>
                    <template v-for="format in formats" :key="format.name">
                        <option v-if="canCreateFormat(format.name)" :value="format.name">{{ format.name }}</option>
                    </template>
                </select>
                <span class="input-group-addon">
                    <i class="fas fa-fw" :class="currentFormat.icon"></i>
                </span>
            </div>
        </div>

        <div class="form-group">
            <label class="form-label" for="language">{{ $t('main.language') }}</label>
            <select v-model="report.language" class="form-select" id="language" required>
                <option v-for="(lang, code) in locales" :key="code" :value="code">{{ lang }}</option>
            </select>
        </div>

        <div class="form-group">
            <label class="form-label" for="layout">{{ $t('reports.layout') }}</label>
            <select v-model="report.template" class="form-select" id="layout" required>
                <option v-for="template in templates" :key="template.id" :value="template.code">
                    {{ template.name }}
                </option>
            </select>
        </div>

        <template #footer>
            <SaveButton @click="save()">{{ $t('reports.create_report', fileIds.length) }}</SaveButton>
        </template>
    </Modal>
</template>

<script>
import { tenantCanCreateDocxExport } from '~/guards/featureGuard.js'

export default {
    data() {
        return {
            errors: null,
            
            templates: [],
            locales: window.config.locales,
            useReportInBrowser: this.$store.state.user.properties.use_report_in_browser,

            formats: [
                { name: 'pdf', icon: 'fa-file-pdf' },
                { name: 'docx', icon: 'fa-file-word' },
                { name: 'json', icon: 'fa-code' },
            ],

            report: {
                timezone: dayjs().format('Z'),
                format: this.$store.state.lastReportCreatedValues.format || 'pdf',
                template: this.$store.state.lastReportCreatedValues.template || '1',
                language: this.$store.state.lastReportCreatedValues.language || this.$store.state.language,
                name: `${this.$store.state.user.name} ${new Date().toISOString().substring(0, 10)}`,
            },
        }
    },

    computed: {
        fileIds() {
            const ids = this.$route.params.id || this.$route.query.files || []

            return Array.isArray(ids) ? ids : [ids]
        },

        currentFormat() {
            return this.formats.find(format => format.name === this.report.format) || {}
        },
    },

    async mounted() {
        if (this.useReportInBrowser) {
            window.open(`/files/${this.fileIds.join(',')}/print`, '_blank')
            this.$gtag.event('create_report_in_browser')
            return this.back()
        }

        const { data: templates } = await this.$axios.get('report_templates')
        this.templates = templates
    },

    methods: {
        back() {
            this.$route.params.id ?
                this.$router.push({ name: 'files.show' }) :
                this.$router.push({ name: 'files' })
        },

        canCreateFormat(format) {
            if (format === 'json') return this.$store.state.hasAllAbilities
            if (format === 'docx') return tenantCanCreateDocxExport(this.$store.state.user.tenant_id)

            return true
        },

        async save() {
            this.errors = null

            try {
                await this.$axios.post('reports', { ...this.report, files: this.fileIds })

                this.$toast(this.$root.$t('reports.being_generated'))
                this.$store.commit('SET_LAST_REPORT_CREATED_VALUES', this.report)
                this.$router.push({ name: 'reports' })
                this.$gtag.event('create_report_legacy')
            } catch ({ response }) {
                this.errors = response
            }
        },
    },
}
</script>
