<template>
    <WatchForUnsavedChanges ref="watch" v-model="device" v-slot="{ hasChanges }">
        <Spinner v-if="!device.id"></Spinner>
        <SidePanel v-else :title="$t('main.device')" @close="$router.push({ name: 'settings.devices' })">
            <router-view :device="device" @activated="onActivate()"></router-view>

            <div class="form-group">
                <Errors :errors="errors" />
            </div>

            <fieldset :disabled="!canEdit">
                <div v-if="showCargy" class="text-center">
                    <img height="250" src="/images/snappy/thumbup.png" alt style="margin-top: -44px" />
                    <p class="text-bold">{{ $t('devices.device_activated') }}</p>
                </div>

                <div class="form-group">
                    <label class="form-label" for="nickname">{{ $t('main.name') }}</label>
                    <input v-model="device.nickname" class="form-input" id="nickname" :placeholder="$t('main.name')"
                        required />
                </div>

                <div class="form-group">
                    <label class="form-label" for="location">{{ $t('main.location') }}</label>
                    <select v-model="device.location_id" class="form-select" id="location" required>
                        <option v-for="location in locations" :value="location.id">{{ location.location }}</option>
                    </select>
                </div>

                <details class="accordion mb-4" v-if="device.advanced_settings">
                    <summary class="accordion-header">
                        <i class="fas fa-chevron-right mr-2"></i>
                        <span>{{ $t('devices.advanced_settings') }}</span>
                    </summary>
                    <div class="accordion-body">
                        <div class="mx-2 p-2">
                            <div class="mb-4">
                                <label class="form-checkbox">
                                    <input type="checkbox" v-model="device.delete_after_upload" :true-value="false"
                                        :false-value="true" />
                                    <i class="form-icon"></i>
                                    <span>{{ $t('devices.delete_after_upload') }}</span>
                                </label>

                                <label class="form-checkbox">
                                    <input type="checkbox" v-model="device.allow_upload_without_wifi" />
                                    <i class="form-icon"></i>
                                    <span>{{ $t('devices.allow_upload_without_wifi') }}</span>
                                </label>

                                <label class="form-checkbox" :class="{ ghost: device.allow_upload_without_wifi }">
                                    <input type="checkbox" v-model="device.force_damage_upload" />
                                    <i class="form-icon"></i>
                                    <span>{{ $t('devices.force_damage_upload') }}</span>
                                </label>
                            </div>

                            <div class="mb-2">
                                <button class="btn" @click="$refs.widgetBuilder?.showModal()">
                                    <i class="fas fa-fw fa-mobile-alt mr-2"></i>
                                    <span>{{ $t('devices.manage_widgets') }}</span>
                                </button>
                            </div>

                            <div class="mb-2">
                                <button class="btn" @click="$refs.regexBuilder?.showModal()">
                                    <i class="fas fa-fw fa-exclamation-triangle mr-2"></i>
                                    <span>{{ $t('regex.reference_warning') }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </details>

                <div class="flex-space mb-4">
                    <SaveButton @click="save()" class="mr-2" :disabled="!hasChanges" />

                    <button class="btn btn-secondary" @click="deactivate()" v-if="device.activated === true">
                        {{ $t('devices.deactivate') }}
                    </button>

                    <router-link tag="button" class="btn btn-secondary"
                        v-else-if="$route.name !== 'settings.devices.activate'"
                        :to="{ name: 'settings.devices.activate' }">
                        {{ $t('devices.activate') }}
                    </router-link>

                    <button class="btn btn-error ml-auto" @click="deleteDevice()">{{ $t('main.delete') }}</button>
                </div>

                <DeviceAttributes :version="device.app_version" :attributes="device.attributes"></DeviceAttributes>
            </fieldset>

            <div class="dev-feature" v-if="$store.state.hasAllAbilities && device.id">
                <a href="#" @click.prevent="getApiSettingsUrl()">API Settings Call</a>

                <label class="form-checkbox">
                    <input type="checkbox" v-model="device.properties.insights.metrics.enabled" />
                    <i class="form-icon"></i>
                    <span>Enable metrics</span>
                </label>

                <label class="form-checkbox">
                    <input type="checkbox" v-model="device.properties.insights.logging.enabled" />
                    <i class="form-icon"></i>
                    <span>Enable logging</span>
                </label>

                <select class="form-select" :class="{ ghost: !device.properties.insights.logging.enabled }"
                    v-model="device.properties.insights.logging.severity">
                    <option :value="0">Debug</option>
                    <option :value="1">Info</option>
                    <option :value="2">Warning</option>
                    <option :value="3">Error</option>
                    <option :value="4">Fatal</option>
                </select>
            </div>

            <WidgetBuilder ref="widgetBuilder" :widgets="device.widgets" @save="saveWidgets" />
            <RegexBuilder ref="regexBuilder" :regex="device.scancode_regex" :custom="device.scancode_custom_regex"
                @save="saveScancodeRegex" />
        </SidePanel>
    </WatchForUnsavedChanges>
</template>

<script>
import SidePanel from '~/components/SidePanel.vue'
import RegexBuilder from '~/components/builders/RegexBuilder.vue'
import WidgetBuilder from '~/components/builders/WidgetBuilder.vue'
import DeviceAttributes from '~/components/DeviceAttributes.vue'

export default {
    props: ['locations'],
    emits: ['refresh'],

    components: {
        SidePanel,
        WidgetBuilder,
        RegexBuilder,
        DeviceAttributes,
    },

    data() {
        return {
            device: {},
            errors: null,
            showCargy: false,

            id: this.$route.params.id,
            canEdit: this.$store.getters.can('Update devices'),
        }
    },

    async mounted() {
        const { data } = await this.$axios.get(`devices/${this.id}`)

        if (!data.properties) {
            data.properties = { insights: { logging: { enabled: false, severity: 0 }, metrics: { enabled: false } } }
        }

        if (this.$store.state.user.role === 'Manager') {
            this.canEdit = this.$store.state.user.location_id === data.location_id
        }

        this.device = data
    },

    methods: {
        async getApiSettingsUrl() {
            const { data } = await this.$axios.get(`devices/${this.id}/get_developer_api_settings_url`)
            window.open(data, '_blank')
        },

        async save() {
            this.errors = null

            try {
                await this.$axios.patch(`devices/${this.id}`, this.device)

                this.$emit('refresh')
                this.$toast(this.$root.$t('i.update', { i: this.device.nickname }))
                this.$refs.watch?.init()

            } catch ({ response }) {
                this.errors = response
            }
        },

        onActivate() {
            this.showCargy = true
            this.device.activated = true
            this.$refs.watch?.init()

            this.$router.replace({ name: 'settings.devices.show' })
            this.$toast(this.$root.$t('devices.device_activated'))
        },

        saveWidgets(widgets) {
            this.device.widgets = widgets
            this.save()
        },

        saveScancodeRegex(regex, custom) {
            this.device.scancode_regex = regex
            this.device.scancode_custom_regex = custom
            this.save()
        },

        deactivate() {
            this.$dialog.confirm(async () => {
                const { data } = await this.$axios.patch(`devices/${this.id}/deactivate`)

                this.showCargy = false
                this.device.activated = false
                this.device.token = data.token
                this.$refs.watch?.init()
                this.$emit('refresh')
            }, { buttonText: this.$t('devices.deactivate') })
        },

        deleteDevice() {
            this.$dialog.danger(async () => {
                await this.$axios.delete(`devices/${this.id}`)

                this.$refs.watch?.init()
                this.$router.push({ name: 'settings.devices' })
                this.$toast(this.$root.$t('i.delete', { i: this.device.nickname }))
                this.$emit('refresh')
            })
        },
    },
}
</script>
