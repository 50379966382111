<template>
    <SidePanel :title="$t('context.create_devices')" @close="$router.push({ name: 'settings.devices' })">
        <template v-if="deviceCount < deviceLimit">
            <div class="form-group">
                {{ $t('devices.you_have_active_devices', { current: deviceCount, max: deviceLimit }) }}
            </div>

            <div class="form-group">
                <Errors :errors="errors" />
            </div>

            <div class="form-group">
                <label class="form-label" for="location">{{ $t('main.location') }}</label>
                <select v-model="device.location_id" id="location" class="form-select">
                    <option v-for="location in locations" :key="location.id" :value="location.id">
                        {{ location.location }}
                    </option>
                </select>
            </div>

            <div class="form-group">
                <label class="form-label" for="name">{{ $t('main.name') }}</label>
                <input id="name" class="form-input" v-model="device.nickname" :placeholder="$t('main.name')" />
            </div>

            <SaveButton @click="create()">{{ $t('main.create') }}</SaveButton>
        </template>
        <NeedsPremiumLicense v-else :text="$t('license_limit.devices')"></NeedsPremiumLicense>
    </SidePanel>
</template>

<script>
import SidePanel from '~/components/SidePanel.vue'
import NeedsPremiumLicense from '~/components/NeedsPremiumLicense.vue'

export default {
    props: ['locations'],

    components: { SidePanel, NeedsPremiumLicense },

    data() {
        return {
            errors: null,
            device: { location_id: this.$store.state.user.location_id },
        }
    },

    computed: {
        deviceCount() {
            return this.$store.state.user.company?.counts.devices
        },

        deviceLimit() {
            return this.$store.state.user.company?.license_devices_limit
        }
    },

    async mounted() {
        await this.$store.dispatch('fetchUser')
    },

    methods: {
        async create() {
            try {
                const { data } = await this.$axios.post('devices', this.device)

                this.$emit('refresh')
                this.$router.push({ name: 'settings.devices.activate', params: { id: data.id } })
                this.$toast(this.$root.$t('i.create', { i: this.device.nickname }))
            } catch ({ response }) {
                this.errors = response
            }
        },
    },
}
</script>
