<template>
    <div>
        <!-- TODO: create ability to create issues -->
        <ContextBar :title="$t('main.issues')" :buttonText="$t('context.create_issues')" buttonRoute="issues.create">
            <DebounceInput v-model="search" clearable searchIcon />
        </ContextBar>

        <DataTable endpoint="issues" :columns="columns" :parameters="parameters" sort="created_at" hasFilters>
            <template #context-right>
                <select class="form-select" v-model="status">
                    <option :value="undefined">{{ $t('issues.issues_open_and_closed') }}</option>
                    <option :value="'1'">{{ $t('issues.issues_open') }}</option>
                    <option :value="'0'">{{ $t('issues.issues_closed') }}</option>
                </select>

                <MultiSelect v-model="assignments" label="name" :options="users" :reduce="u => u.id"
                    :placeholder="$t('issues.assigned_to')" multiple avatar>
                </MultiSelect>
            </template>

            <template #column(issue_number)="{ value }">{{ value }}</template>
            <template #column(title)="{ item }">
                <router-link :to="{ name: 'issues.show', params: { issue_number: item.issue_number } }"
                    class="table-link">
                    {{ item.title }}
                </router-link>
            </template>
            <template #column(created_at)="{ value }">
                <DateTime :date="value"></DateTime>
            </template>
            <template #column(status)="{ value }">
                <div class="chip chip-lg" :class="[value ? 'chip-error' : 'text-gray']">
                    <i class="fas fa-circle"></i>
                    <span class="ml-2">{{ $t('issues.issue_status', value) }}</span>
                </div>
            </template>
            <template #column(assignments)="{ value }">
                <AvatarRow :items="value">
                    <template #avatar="{ item }">
                        <div class="tooltip tooltip-bottom" :data-tooltip="item.assignee?.name">
                            <Avatar :initials="item.assignee?.name"></Avatar>
                        </div>
                    </template>
                    <template #remainingItems="{ items }">
                        <div class="tooltip tooltip-bottom" :data-tooltip="items.map(i => i.assignee?.name).join('\n')">
                            <figure class="avatar bg-dark" :data-initial="`+${items.length}`"></figure>
                        </div>
                    </template>
                </AvatarRow>
            </template>
        </DataTable>

        <router-view></router-view>
    </div>
</template>

<script>
import AvatarRow from '~/components/AvatarRow.vue'
import Avatar from '~/components/Avatar.vue'

export default {
    components: { Avatar, AvatarRow },

    data() {
        return {
            users: [this.$store.state.user],
            search: this.$route.query['search'],
            status: this.getStatusFromQuery(),
            assignments: this.getAssignmentsFromQuery(),

            columns: [
                { name: 'issue_number', th: '#', class: 'mw-250' },
                { name: 'title', th: 'issues.description', class: 'mw-250', sortable: true },
                { name: 'created_at', th: 'main.created', sortable: true },
                { name: 'status', th: 'issues.status' },
                { name: 'assignments', th: 'issues.assignees' },
            ],
        }
    },

    computed: {
        parameters() {
            const assignments = this.assignments && this.assignments.length ? this.assignments.join(',') : undefined

            return {
                assignments,
                search: this.search,
                status: this.status,
            }
        },
    },

    watch: {
        parameters(parameters) {
            this.$store.commit('UPDATE_ISSUE_INDEX_FILTER_STATUS', parameters.status)
            this.$store.commit('UPDATE_ISSUE_INDEX_FILTER_ASSIGNEES', parameters.assignments || '')
        },
    },

    async mounted() {
        const { data: paginator } = await this.$axios.get('users', {
            params: { limit: 200, sort: 'name', by: 'asc' },
        })

        // show self as the first user in the list
        const index = paginator.data.findIndex(u => u.id === this.$store.state.user.id)
        if (index > -1) paginator.data.splice(index, 1)

        paginator.data.unshift(this.$store.state.user)

        this.users = paginator.data
    },

    methods: {
        getStatusFromQuery() {
            return this.$route.query['status'] || this.$store.state.issueIndexFilterStatus
        },

        getAssignmentsFromQuery() {
            const assignments = this.$route.query['assignments'] || this.$store.state.issueIndexFilterAssignees

            return assignments.split(',').map(Number).filter(Number)
        }
    }
}
</script>