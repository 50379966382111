<template>
    <div class="dark-heading">
        <Spinner v-if="!file.id"></Spinner>

        <FileShow v-else :file="file" backRoute="files" @editReference="editReference()"
            @toggleClosed="file.closed_at ? openFile() : closeFile()" @refresh="fetchData()" :class="{
            'can-edit': canEdit,
            'can-open': canOpen,
            'can-close': canClose,
            'can-delete': canDelete,
            'can-send-images': canSendImages,
            'can-create-snap': canCreateSnap,
            'can-create-submit': canCreateSubmit,
            'can-edit-snap': canEditSnaps,
            'can-edit-submit': canEditSubmits,
            'can-delete-snap': canDeleteSnaps,
            'can-delete-submit': canDeleteSubmits,
            'can-share-file': canShareFile,
            'can-view-history': canViewHistory,
            'can-create-field': canCreateField,
            'can-create-report': canCreateReport,
            'can-download-images': canDownloadImages,
        }">
            <template #actions>
                <router-link tag="button" class="btn send-images hide-xl" :to="{ name: 'files.send_images' }">
                    <i class="fas fa-paper-plane mr-2"></i>
                    <span>{{ $t('files.send_by_email') }}</span>
                    <small v-if="file.images_sent" class="label label-success ml-2">
                        <DateTime :date="file.images_sent"></DateTime>
                    </small>
                </router-link>

                <router-link tag="button" class="btn hide-md create-report"
                    :to="{ name: 'files.create_report', params: { id: file.id } }">
                    <i class="fas fa-file-invoice mr-2"></i>
                    {{ $t('files.create_report') }}
                </router-link>

                <router-link tag="button" class="btn hide-md share-file" :to="{ name: 'files.share.create' }"
                    :class="{ badge: file.shares_count }" :data-badge="file.shares_count">
                    <i class="fas fa-share-nodes mr-2"></i>
                    {{ $t('files.share') }}
                </router-link>

                <Dropdown class="dropdown-right">
                    <div class="menu-item send-images show-xl">
                        <router-link :to="{ name: 'files.send_images' }">
                            <span>{{ $t('files.send_by_email') }}</span>
                            <i v-if="file.images_sent" class="fas fa-check ml-2"></i>
                        </router-link>
                    </div>
                    <div class="menu-item show-md create-report">
                        <router-link :to="{ name: 'files.create_report', params: { id: file.id } }">
                            {{ $t('files.create_report') }}
                        </router-link>
                    </div>
                    <div class="menu-item show-md share-file">
                        <router-link :to="{ name: 'files.share.create' }">{{ $t('files.share') }}</router-link>
                    </div>
                    <div class="menu-item edit">
                        <a href="#" @click.prevent="editReference()">{{ $t('files.edit_reference') }}</a>
                    </div>
                    <div class="menu-item create-snap">
                        <router-link :to="{ name: 'files.upload' }">{{ $t('files.upload') }}</router-link>
                    </div>
                    <div class="menu-item create-submit">
                        <router-link :to="{ name: 'files.submit_form' }">{{ $t('files.submit_form') }}</router-link>
                    </div>
                    <div class="menu-item download-images">
                        <router-link :to="{ name: 'files.download_zip' }">{{ $t('main.download') }}</router-link>
                    </div>
                    <div class="menu-item create-field">
                        <router-link :to="{ name: 'files.add_field', params: { id: file.id } }">
                            {{ $t('files.add_field') }}
                        </router-link>
                    </div>
                    <div class="menu-item view-history">
                        <router-link :to="{ name: 'files.history' }">{{ $t('files.history') }}</router-link>
                    </div>
                    <div class="menu-item create-issue">
                        <router-link :to="{ name: 'files.create_issue' }">{{ $t('context.create_issues')
                            }}</router-link>
                    </div>
                    <div class="menu-item close-file" v-if="!file.closed">
                        <a href="#" @click.prevent="closeFile()">{{ $t('files.close_files') }}</a>
                    </div>
                    <div class="menu-item open-file" v-else>
                        <a href="#" @click.prevent="openFile()">{{ $t('files.open_file') }}</a>
                    </div>
                    <div class="menu-item delete">
                        <a href="#" @click.prevent="deleteFile()" class="text-error">
                            {{ $t('files.delete_files') }}</a>
                    </div>
                </Dropdown>
            </template>
        </FileShow>

        <router-view v-if="file.id" :file="file" @refresh="fetchData()"></router-view>
    </div>
</template>

<script>
import FileShow from '~/components/files/FileShow.vue'
import { DialogError } from '~/plugins/dialog.js'

export default {
    components: { FileShow },

    data() {
        return {
            file: {},

            canEdit: this.$store.getters.can('Update files'),
            canOpen: this.$store.getters.can('Open files'),
            canClose: this.$store.getters.can('Close files'),
            canDelete: this.$store.getters.can('Delete files'),
            canSendImages: this.$store.getters.can('Use send email images from files'),

            canCreateSnap: this.$store.getters.can('Create snaps'),
            canCreateSubmit: this.$store.getters.can('Create form submits'),
            canEditSnaps: this.$store.getters.can('Update snaps'),
            canEditSubmits: this.$store.getters.can('Update form submits'),
            canDeleteSnaps: this.$store.getters.can('Delete snaps'),
            canDeleteSubmits: this.$store.getters.can('Delete form submits'),

            canShareFile: this.$store.getters.can('Create file shares'),
            canViewHistory: this.$store.getters.can('Read file history'),
            canCreateField: this.$store.getters.can('Create file tags'),
            canCreateReport: this.$store.getters.can('Create reports'),
            canDownloadImages: this.$store.getters.can('Download images as zip'),
        }
    },

    mounted() {
        this.fetchData()
    },

    watch: { '$route.params.id': 'fetchData' },

    methods: {
        async fetchData() {
            this.file = {}

            const { data: file } = await this.$axios.get(`files/${this.$route.params.id}`)

            this.file = file
        },

        async editReference() {
            if (!this.canEdit) return

            let mergeFile = false

            await this.$dialog.confirm(async (new_scan_code) => {
                try {
                    await this.$axios.patch(`files/${this.file.id}`, { new_scan_code })
                    this.file.scan_code = new_scan_code
                } catch ({ response }) {
                    if (!response.data) return

                    if (response.data.message) {
                        throw new DialogError(response.data.message)
                    }

                    mergeFile = response.data
                }
            }, {
                input: this.file.scan_code,
                title: this.$t('files.edit_reference'),
                buttonText: this.$t('main.update'),
            })

            if (!mergeFile) return

            // user wants to change the reference that already exists
            // so we show a dialog asking if they want to merge the files
            this.showDialogToMergeFile(mergeFile)
        },

        showDialogToMergeFile(newFile) {
            const text = this.$t('files.reference_merge', { current: this.file.scan_code, new: newFile.scan_code })

            this.$dialog.confirm(async () => {
                const { data } = await this.$axios.post(`files/${this.file.id}/merge/${newFile.id}`)
                this.$router.push({ name: 'files.show', params: { id: data.id } })
            }, { text, title: this.$t('files.reference_exists'), buttonText: this.$t('files.yes_merge_files') })
        },

        openFile() {
            if (!this.canOpen) return

            this.$dialog.confirm(async () => {
                try {
                    await this.$axios.patch(`files/${this.file.id}/open`)

                    this.file.closed = false
                    this.file.closed_at = null
                    this.$toast(this.$root.$t('files.open'))
                } catch ({ response }) {
                    throw new DialogError(response.data?.file[0])
                }
            }, { buttonText: this.$t('files.open_file'), text: this.$t('files.open_warning') })
        },

        closeFile() {
            if (!this.canClose) return

            this.$dialog.confirm(async () => {
                await this.$axios.patch(`files/${this.file.id}/close`)

                this.file.closed = true
                this.file.closed_at = new Date()
                this.$toast(this.$root.$t('files.closed_file'))
            }, { buttonText: this.$t('files.close_files'), text: this.$t('files.close_warning') })
        },

        deleteFile() {
            if (!this.canDelete) return

            this.$dialog.danger(async () => {
                await this.$axios.delete(`files/${this.file.id}`)
                this.$toast(this.$root.$t('i.delete', { i: this.file.scan_code }))
                this.$router.push({ name: 'files' })
            })
        },
    },
}
</script>
