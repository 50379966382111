<template>
    <a href="#" class="chip chip-lg m-0" :class="[mostRecentIssue.status ? 'chip-error' : 'text-gray']"
        @click.prevent="showModal()">
        <span class="text-ellipsis">{{ mostRecentIssue.title }}</span>
        <span class="text-bold ml-1" v-if="openIssues.length > 1">+{{ openIssues.length - 1 }}</span>
    </a>

    <Modal v-if="modalOpen" :title="`${$t('main.issues')} (${issues.length})`" @close="modalOpen = false">
        <div v-if="openIssues.length" class="mb-6">
            <h4>{{ $t('issues.issues_open') }}</h4>
            <FileShowIssuesItem v-for="issue in openIssues" :key="issue.id" :issue="issue" />
        </div>

        <div v-if="closedIssues.length">
            <h4>{{ $t('issues.issues_closed') }}</h4>
            <FileShowIssuesItem v-for="issue in closedIssues" :key="issue.id" :issue="issue" />
        </div>
    </Modal>
</template>

<script>
import FileShowIssuesItem from './FileShowIssuesItem.vue'

export default {
    props: { issues: Array },

    components: { FileShowIssuesItem },

    data: () => ({ modalOpen: false }),

    computed: {
        sortedIssues() {
            return [...this.issues].reverse()
        },

        openIssues() {
            return this.sortedIssues.filter(issue => issue.status === 1)
        },

        closedIssues() {
            return this.sortedIssues.filter(issue => issue.status === 0)
        },

        mostRecentIssue() {
            return this.openIssues[0] || this.closedIssues[0] || {}
        },
    },

    methods: {
        showModal() {
            if (this.issues.length === 1) {
                this.$router.push({ name: 'issues.show', params: { issue_number: this.mostRecentIssue.issue_number } })
            } else {
                this.modalOpen = true
            }
        }
    }
}
</script>