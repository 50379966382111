<template>
    <WatchForUnsavedChanges ref="watch" v-model="tenant" v-slot="{ hasChanges }">
        <Spinner v-if="!tenant.id"></Spinner>
        <Modal v-else :title="tenant.company" @close="$router.push({ name: 'companies' })">
            <div class="form-group">
                <Errors :errors="errors" />
            </div>

            <div class="form-horizontal">
                <div class="form-group">
                    <div class="col-3 col-sm-12">
                        <label class="form-label" for="company">Company</label>
                    </div>
                    <div class="col-9 col-sm-12">
                        <input v-model="tenant.company" id="company" class="form-input" />
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-3 col-sm-12">
                        <label class="form-label" for="domain">Domain</label>
                    </div>
                    <div class="col-9 col-sm-12">
                        <input v-model="tenant.domain" id="domain" class="form-input" />
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-3 col-sm-12">
                        <label class="form-label" for="phone">Phone number</label>
                    </div>
                    <div class="col-9 col-sm-12">
                        <input v-model="tenant.phone" id="phone" class="form-input" />
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-3 col-sm-12">
                        <label class="form-label" for="country">Country</label>
                    </div>
                    <div class="col-9 col-sm-12">
                        <input v-model="tenant.country" id="country" class="form-input" />
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-3 col-sm-12">
                        <label class="form-label" for="region">Region</label>
                    </div>
                    <div class="col-9 col-sm-12">
                        <select v-model="tenant.region" id="region" class="form-select">
                            <option v-for="region in regions" :key="region.name" :value="region.name">
                                {{ region.title }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-3 col-sm-12">
                        <label class="form-label" for="limit">Max devices</label>
                    </div>
                    <div class="col-9 col-sm-12">
                        <input type="number" v-model.number="tenant.license_devices_limit" id="limit"
                            class="form-input" />
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-3 col-sm-12">
                        <label class="form-label" for="status">Status</label>
                    </div>
                    <div class="col-9 col-sm-12">
                        <select v-model="tenant.status" id="status" class="form-select" @change="changeStatus">
                            <option v-for="status in statuses" :key="status.name" :value="status.name"
                                :disabled="!status.selectable">
                                {{ status.title }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-group" v-if="tenant.status === 'trial'">
                    <div class="col-3 col-sm-12">
                        <label class="form-label" for="expiry">Trial expiry</label>
                    </div>
                    <div class="col-9 col-sm-12">
                        <DatePicker v-model="tenant.trial_expires_at" placeholder="Trial expiry" required>
                        </DatePicker>
                    </div>
                </div>

                <div class="mb-2">
                    <div v-if="tenant.abowire_subscription_id" class="form-group">
                        <div class="col-3 col-sm-12"></div>
                        <div class="col-9 col-sm-12">Customer has a paid subscription</div>
                    </div>

                    <template v-else>
                        <div class="form-group">
                            <div class="col-3 col-sm-12">
                                <label class="form-label" for="currency">Currency</label>
                            </div>
                            <div class="col-9 col-sm-12">
                                <select v-model="tenant.license_currency" id="currency" class="form-select">
                                    <option v-for="currency in currencies" :key="currency.id" :value="currency.value">
                                        {{ currency.name }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="col-3 col-sm-12">
                                <label class="form-label" for="plan">Default Subscription</label>
                            </div>
                            <div class="col-9 col-sm-12">
                                <select id="plan" class="form-select" v-model="tenant.abowire_default_sku"
                                    :class="{ loading: fetchingData }">
                                    <option :value="null">Default (standard)</option>
                                    <option v-for="plan in plans" :key="plan.id" :value="plan.sku">
                                        {{ plan.name }} ({{ plan.sku }})
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="col-3 col-sm-12">
                                <label class="form-label" for="coupon">Default Coupon</label>
                            </div>
                            <div class="col-9 col-sm-12">
                                <select id="coupon" class="form-select" v-model="tenant.abowire_default_coupon_code"
                                    :class="{ loading: fetchingData }">
                                    <option :value="null">Default (no coupon)</option>
                                    <option v-for="coupon in coupons" :key="coupon.id" :value="coupon.code">
                                        {{ coupon.name }} ({{ coupon.code }})
                                    </option>
                                </select>
                            </div>
                        </div>
                    </template>
                </div>

                <div class="form-group">
                    <div class="col-3 col-sm-12"></div>
                    <div class="col-9 col-sm-12">
                        <label class="form-checkbox">
                            <input type="checkbox" v-model="tenant.use_weather_api" />
                            <i class="form-icon"></i>
                            <span>Weather data per snap</span>
                            <small class="text-bold ml-2">€ 5 per licence per month</small>
                        </label>
                    </div>
                    <div class="col-3 col-sm-12"></div>
                    <div class="col-9 col-sm-12">
                        <label class="form-checkbox">
                            <input type="checkbox" v-model="tenant.use_kiosks" />
                            <i class="form-icon"></i>
                            <span>Kiosks</span>
                            <small class="text-bold ml-2">€ 25 per licence per month</small>
                        </label>
                    </div>

                    <div class="col-3 col-sm-12"></div>
                    <div class="col-9 col-sm-12">
                        <label class="form-checkbox">
                            <input type="checkbox" v-model="tenant.use_report_in_browser" />
                            <i class="form-icon"></i>
                            <span>Generate reports in browser (DISABLE REPORT ENGINE)</span>
                        </label>
                    </div>
                    <div class="col-3 col-sm-12"></div>
                    <div class="col-9 col-sm-12">
                        <small v-if="$store.state.user.tenant_id === tenant.id" class="text-gray">
                            <span class="text-error">You cannot block yourself!</span><br>
                            To prevent you from locking yourself out. <br>
                            If you are impersonating the tenant you want to block, first logout of their account.
                        </small>
                        <label class="form-checkbox text-error">
                            <input type="checkbox" v-model="tenant.soft_blocked" :disabled="$store.state.user.tenant_id === tenant.id"/>
                            <i class="form-icon"></i>
                            <span>Block access</span>
                            <small class="text-bold ml-1">This will grey out all screens on web app!</small>
                        </label>
                    </div>
                </div>
            </div>

            <template #footer>
                <div class="flex-space">
                    <SaveButton @click="save()" class="mr-auto" :disabled="!hasChanges" />

                    <router-link tag="button" class="btn mr-2"
                        :to="{ name: 'companies.stats' }">Statistics</router-link>
                    <button class="btn" @click="impersonateTenant()">Impersonate</button>
                </div>
            </template>
        </Modal>
    </WatchForUnsavedChanges>
</template>

<script>
export default {
    props: ['regions', 'statuses'],
    emits: ['refresh'],

    data() {
        return {
            tenant: {},
            oldStatus: '',

            errors: null,
            id: this.$route.params.id,
            currencies: window.config.currencies,

            plans: [],
            coupons: [],
            fetchingData: false,
        }
    },

    async mounted() {
        const { data: tenant } = await this.$axios.get(`admin/tenants/${this.id}`)

        this.tenant = tenant
        this.oldStatus = this.tenant.status

        if (this.tenant.trial_expires_at) {
            this.tenant.trial_expires_at = new Date(this.tenant.trial_expires_at)
        }
    },

    watch: {
        async 'tenant.license_currency'(currency) {
            this.fetchingData = true

            try {
                await Promise.all([this.fetchAbowirePlans(currency), this.fetchAbowireCoupons(currency)])
            } catch { }

            this.fetchingData = false
        }
    },

    methods: {
        async save() {
            const tenant = this.tenant

            if (this.oldStatus !== tenant.status) {
                if (tenant.status === 'deleted') {
                    if (!confirm('This will disable the tenant, are you REALLY sure?')) return
                }

                if (tenant.status === 'free') {
                    if (!confirm('This will set all users in this tenant to FREE')) return
                }
            }

            try {
                await this.$axios.patch(`admin/tenants/${tenant.id}`, tenant)

                this.$toast(this.$root.$t('i.update', { i: tenant.company }))
                this.$refs.watch?.init()

                this.$router.push({ name: 'companies' })
                this.$emit('refresh')
            } catch ({ response }) {
                this.errors = response
            }
        },

        changeStatus() {
            if (this.tenant.status === 'trial') {
                if (!this.tenant.trial_expires_at) {
                    this.tenant.trial_expires_at = dayjs().add(2, 'week').toDate()
                }
            } else {
                this.tenant.trial_expires_at = null
            }
        },

        async fetchAbowirePlans(currency) {
            const { data: plans } = await this.$axios.get('admin/abowire/plans', { params: { currency } })
            this.plans = plans
        },

        async fetchAbowireCoupons(currency) {
            const { data: coupons } = await this.$axios.get('admin/abowire/coupons', { params: { currency } })
            this.coupons = coupons
        },

        impersonateTenant() {
            this.$dialog.confirm(async () => {
                await this.$axios.post(`admin/impersonate/${this.tenant.id}`)
                await this.$store.dispatch('fetchUser')

                this.$refs.watch?.init()
                this.$router.push({ name: 'dashboard' })
            }, { text: 'You are about to impersonate. Any action taken will persist.' })
        },
    },
}
</script>
