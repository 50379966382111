<script setup>
import { event } from 'vue-gtag'

const model = defineModel()
const props = defineProps({ workflow_runs: Array })

const TIME_UNITS = [
    { unit: 'day', seconds: 86400, suffix: 'd' },
    { unit: 'hour', seconds: 3600, suffix: 'h' },
    { unit: 'minute', seconds: 60, suffix: 'm' },
    { unit: 'second', seconds: 1, suffix: 's' }
]

const runs = [...props.workflow_runs].filter(run => !!run.client_key).sort((a, b) => b.id - a.id)
const options = runs.map(run => ({ label: run.workflow.name, ...run }))

const formatDate = date => dayjs(date).format('D MMM HH:mm')

const duration = run => {
    const start = dayjs(run.submit_date_time)
    const end = dayjs(run.completed_at)
    const totalSeconds = end.diff(start, 'second')

    const parts = TIME_UNITS.map(({ seconds, suffix }, index) => {
        const nextUnit = TIME_UNITS[index - 1]
        const value = Math.floor((totalSeconds % (nextUnit?.seconds || Infinity)) / seconds)
        return value > 0 ? `${value}${suffix}` : ''
    }).filter(Boolean)

    return parts.length ? parts.join(' ') : '0s'
}

const onOpen = () => {
    event('file_wf_dropdown_opened')
}

const onSelected = () => {
    event('file_wf_dropdown_selected')
}
</script>

<template>
    <div class="file-workflow-run-filter-dropdown">
        <MultiSelect v-if="options.length" v-model="model" :options="options" :reduce="run => run.id"
            :placeholder="$t('files.workflow_run_filter')" :hideSelected="false" style="width: 350px"
            @option:selected="onSelected" @open="onOpen">
            <template #option="{ option }">
                <div class="flex-space mb-1">
                    <div class="text-ellipsis" :title="option.label" style="font-weight: 500">{{ option.label }}</div>
                    <div class="ml-2">
                        <i class="fas fa-check fa-xs" v-if="option.completed_at"></i>
                        {{ option.workflow_run_steps.length }}/{{ option.workflow.steps.length }}
                    </div>
                </div>
                <div class="flex-space">
                    <div>{{ formatDate(option.submit_date_time) }}</div>
                    <div v-if="option.completed_at">
                        <i class="far fa-flag-checkered fa-xs"></i>
                        <span class="ml-1">{{ duration(option) }}</span>
                    </div>
                </div>
            </template>
        </MultiSelect>
    </div>
</template>